import React from 'react';
// import { graphql } from 'gatsby';
import { FixedObject } from 'gatsby-image';
import IndexLayout from '../layouts';
import { Helmet } from 'react-helmet';
import SiteNav from '../components/header/SiteNav';
import { Wrapper } from '../components/Wrapper';
import { Footer } from '../components/Footer';
import { css } from '@emotion/core';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
  PostFeed,
  Posts,
  // SiteDescription,
  // SiteHeaderContent,
  // SiteTitle,
  // SiteHeaderStyles,
} from '../styles/shared';
// import { NoImage, PostFull } from '../templates/post';
// import { PostCard } from '../components/PostCard';
import { colors } from '../styles/colors';
import { PageContext } from '../templates/post';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  }
`;

export interface GrowthProps {
  pageContext: {
    currentPage: number;
    numPages: number;
  };
  data: {
    logo: {
      childImageSharp: {
        fixed: FixedObject;
      };
    };
    header: {
      childImageSharp: {
        fixed: FixedObject;
      };
    };
    allMarkdownRemark: {
      edges: Array<{
        node: PageContext;
      }>;
    };
  };
}

const Growth: React.FC = () => {
  return (
    <IndexLayout>
      <Helmet>
        <title>Growth</title>
      </Helmet>
      <Wrapper css={PageTemplate}>
        <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
          <div css={[outer, SiteNavMain]}>
            <div css={inner}>
              <SiteNav isHome={false} />
            </div>
          </div>
        </header>
        <main id="site-main" css={[SiteMain, outer]}>
          <div css={[inner, Posts]}>
            <div css={[PostFeed]}>
              {/* {props.data.allMarkdownRemark.edges.map((post, index) => {
                // filter out drafts in production
                return (
                  (post.node.frontmatter.draft !== true ||
                    process.env.NODE_ENV !== 'production') && (
                    <PostCard key={post.node.fields.slug} post={post.node} large={index === 0} />
                  )
                );
              })} */}
            </div>
          </div>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

// export const pageQuery = graphql`
//   query growthPageQuery($skip: Int!, $limit: Int!) {
//     logo: file(relativePath: { eq: "img/logo-with-text.png" }) {
//       childImageSharp {
//         # Specify the image processing specifications right in the query.
//         # Makes it trivial to update as your page's design changes.
//         fixed {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//     header: file(relativePath: { eq: "img/blog-cover.png" }) {
//       childImageSharp {
//         # Specify the image processing specifications right in the query.
//         # Makes it trivial to update as your page's design changes.
//         fixed(width: 2000, quality: 100) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//     allMarkdownRemark(
//       sort: { fields: [frontmatter___date], order: DESC }
//       filter: { frontmatter: { draft: { ne: true } } }
//       limit: $limit
//       skip: $skip
//     ) {
//       edges {
//         node {
//           timeToRead
//           frontmatter {
//             title
//             date
//             tags
//             draft
//             excerpt
//             image {
//               childImageSharp {
//                 fluid(maxWidth: 3720) {
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }
//             author {
//               id
//               bio
//               avatar {
//                 children {
//                   ... on ImageSharp {
//                     fluid(quality: 100, srcSetBreakpoints: [40, 80, 120]) {
//                       ...GatsbyImageSharpFluid
//                     }
//                   }
//                 }
//               }
//             }
//           }
//           excerpt
//           fields {
//             layout
//             slug
//           }
//         }
//       }
//     }
//   }
// `;

export default Growth;
